<template>
    <b-container>
        <b-row class="vh-100 d-flex justify-content-center align-items-center">
            <b-col sm="4">
                <h1>Local Login</h1>
                <div class="form-group mt-3">
                    <label for="pin"><h4>PIN {{ errorMessage }}</h4></label>
                    <input @input="changePin" name="pin" class="form-control" id="pin">
                </div>
                <div class="form-group mt-3">
                    <button v-bind:disabled="isDisable" @click="loginUser" class="btn btn-primary btn-block">Login</button>
                </div>
                <div class="form-group mt-3">                    
                    <ResetButton />
                </div>
                <div v-if="errorMessage != ''" class="alert alert-danger text-center">
                    {{ errorMessage }}
                </div>                
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import ResetButton from '../components/ResetButton.vue'

export default {
    data: function() {
        return {
            pin: '',
            errorMessage: ''
        }
    },
    components: {
        ResetButton
    },
    methods: {
        changePin: function(event) {
            this.pin = event.target.value
            if(this.pin.length < 4){
                this.errorMessage = ''
            }
        },
        loginUser: function() {
            if(this.pin == this.$store.getters.pin){
                this.$store.commit('setLogStatus', 3)
            }else{
                this.errorMessage = 'Incorrect'
            }
        }
    },
    computed: {
        isDisable: function() {
            if(this.pin.length > 3){
                return false
            }else{
                return true
                
            }
        }
    }
}
</script>