<template>
    <div>        
        <b-sidebar 
            id="sidebarSettings" 
            ref="sidebarSettings" 
            no-header 
            aria-labelledby="sidebarSettings" 
            backdrop 
            bg-variant="dark" 
            text-variant="light" 
            title="Settings"
            right
        >
            <div class="p-3">
                <h4 id="sidebarSettings">Settings</h4>
                <p>V1.1.8</p>
                <v-offline
                    @detected-condition="amIOnline"
                    online-class="online"
                    offline-class="offline"
                    >
                    <template v-if="online">
                        <div class="flex w-full h-full justify-center items-center text-6xl">
                            <p>
                                Get the Latest data from the server, this will over write all existing data.
                            </p>
                            <nav class="mb-3">
                                <b-button variant="success" block @click="getData"><b-icon-box-arrow-in-down></b-icon-box-arrow-in-down> Get Rota</b-button>
                            </nav>
                            <p>
                                Upload your saved data to the server.
                            </p>
                            <nav class="mb-3">
                                <b-button variant="warning" block @click="uploadData"><b-icon-box-arrow-in-up></b-icon-box-arrow-in-up> Upload Data</b-button>
                            </nav>
                        </div>
                    </template>
                    <template v-if="!online">
                        <div class="flex w-full h-full justify-center items-center text-6xl">
                        <p> You are currently offline. Please connect to a network to enable upload or download of jobs.</p>
                        </div>
                    </template>
                </v-offline>
                <nav class="mb-3">
                    <ResetButton />
                </nav>
                <nav class="mb-3">
                    <b-button variant="secondary" block @click="logout"><b-icon-lock-fill></b-icon-lock-fill> Lock</b-button>
                </nav>
                <nav class="mb-3">
                    <b-button variant="primary" block v-b-toggle.sidebarSettings><b-icon-x-circle-fill></b-icon-x-circle-fill> Close Sidebar</b-button>
                </nav>    
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import ResetButton from '../components/ResetButton.vue'
import { VOffline } from "v-offline"

export default {
    components: {
        ResetButton,
        VOffline
    },
    data() {
        return {
        online: true,
        };
    },
    methods: {
        amIOnline(e) {
            this.online = e;
        },
        logout: function() {
            this.$confirm(
                {
                    message: `Are you sure you want to lock the app?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            this.$store.commit('setLogStatus', 2)
                        }
                    }
                }
            )
        },
        
        reset: function() {
        this.$confirm(
            {
                message: `WARNING: All data will be deleted. Are you sure you want to reset the app?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                    this.$store.commit('setLogStatus', 0)
                    this.$store.commit('setUserId', 0)
                    this.$store.commit('setUserPin', '')
                    this.$store.commit('setJobsToDo', [])
                    this.$store.commit('setSelectedJob', null)
                    }
                }
            }
        )
        },
        getData: function(){
            this.$store.commit('toggleUploading')
            axios({
                method: 'POST',
                url: 'https://admin.phoenixhomecarenorfolk.co.uk/api/get-rota.php',    
                headers: {},
                data: {
                    id: this.$store.getters.userId,
                    apikey: this.$store.getters.apikey
                },
                timeout: 5000
            }).then(response =>{
                console.log(response.data.jobs)
                this.insertJobs(response.data.jobs)
                this.$store.commit('toggleUploading')
            }).catch(err => {
                alert(err.message)
                this.$store.commit('toggleUploading')
            })
        },
        insertJobs: function(jobs){
            this.$store.commit('addJobs', jobs)
        },
        uploadData: async function(){
            this.$store.commit('toggleUploading')
            const run = async () => {
                this.$store.commit('setUploadCount', 0)
                var failCount = 0
                for(const job of this.$store.getters.jobsCompleted){
                    try {
                        var response = await this.sendData(job, this.$store.getters.userId, this.$store.getters.apikey) 
                        if(response === 'success'){
                            this.$store.commit('setUploadCount', this.$store.getters.uploadCount + 1)
                            // remove this item from local db
                            this.$store.commit("deleteJob", job.jobId)
                        }else{
                            failCount++
                        }
                    }catch(error) {                        
                        alert("An error occurred, please try again. If problems persist please report error code: " + error)
                        break;
                    }
                }
                alert(this.$store.getters.uploadCount + ' jobs uploaded ok. ' + failCount + ' uploads failed.')
                this.$store.commit('toggleUploading')
            }
            run()            
        },
        sendData: function(job, userId, apiKey){
            return new Promise(function (resolve, reject){
                axios({
                    method: 'POST',
                    url: 'https://admin.phoenixhomecarenorfolk.co.uk/api/update-job.php',    
                    headers: {},
                    data: {
                        id: userId,
                        apikey: apiKey,
                        job: JSON.stringify(job)
                    },
                    timeout: 5000
                }).then(response =>{
                    console.log(response)
                    if(response.data.status === 'success'){
                        resolve(response.data.status)
                    }else{
                        reject(response.data.status)
                    }
                }).catch(err => {
                    alert(err.message)
                    this.$store.commit('toggleUploading')
                })
            })
        }
    },
}
</script>