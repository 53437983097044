<template>
    <b-button variant="danger" block @click="reset"><b-icon-box-arrow-left></b-icon-box-arrow-left> Reset App</b-button>
</template>

<script>
export default {
    methods: {        
        reset: function() {
            this.$confirm(
                {
                    message: `WARNING: All data will be deleted. Are you sure you want to reset the app?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                        this.$store.commit('setLogStatus', 0)
                        this.$store.commit('setUserId', 0)
                        this.$store.commit('setUserPin', '')
                        this.$store.commit('setJobsToDo', [])
                        this.$store.commit('setSelectedJob', null)
                        }
                    }
                }
            )
        }
    }
}
</script>