<template>
    <div> 
        <CameraCodeScanner @scan="onScan" @load="onLoad"/> 
        <h1 class="text-center">Scan To Continue</h1>
        <nav class="mb-3">
            <b-button variant="primary" block @click="cancel">Close Sidebar</b-button>
        </nav>
        <LogModal :selectedJob="$store.getters.selectedJob"></LogModal>
    </div>
</template>

<script>
import { CameraCodeScanner } from "vue-barcode-qrcode-scanner"
import LogModal from '../components/LogModal.vue'

export default {
    components: {
        CameraCodeScanner,
        LogModal
    },
    data: function() {
        return {
            controls: null
        }
    },
    methods: {
        onLoad({ controls }) {
            this.controls = controls
        },
        onScan({ result }) {
            this.$store.commit('setLogLocation', result)
            this.$store.commit('setLogTime', new Date().toISOString())
            this.controls.stop()
            this.$bvModal.show('logModal')
        },
        cancel: function(){
            this.$router.push('/')
        }
    }
}
</script>

<style>
.overlay-element{
    height: 98%!important;
}
</style>