import Vue from 'vue'
import VueRouter from 'vue-router'
import JobsToDo from "../views/JobsToDo"


Vue.use(VueRouter)

import ScannerPage from '../views/ScannerPage.vue';

const routes = [
  { path: '/', name: 'Jobs', component: JobsToDo },
  { path: '/ScannerPage', name: 'Scan',  component: ScannerPage }
]

const router = new VueRouter({
  routes
})

export default router
