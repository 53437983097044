<template>
    <b-modal id="logModal" no-close-on-esc no-close-on-backdrop>  
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <!-- <b-button size="sm" variant="outline-danger" @click="close()">
                Close Modal
            </b-button> -->
            <h5>Log {{ $store.getters.logType }}</h5>
        </template>

        <p><strong>Location:</strong> {{ $store.getters.logLocation }}</p>
        <p><strong>Log Time:</strong> {{ moment($store.getters.logTime).format('hh:mm') }}</p>

        <template #modal-footer>
            <div class="w-100">
                <div class="text-center">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <b-button
                            variant="danger"
                            class="mr-2"
                            @click="cancel"
                        >
                            Cancel
                        </b-button>
                        <b-button
                            v-if="$store.getters.logType == 'In'"
                            variant="success"
                            class="mr-2"
                            @click="log"
                        >
                            Log In
                        </b-button>
                        <b-button
                            v-if="$store.getters.logType == 'Out'"
                            variant="success"
                            class="mr-2"
                            @click="log"
                        >
                            Log Out
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
        
    </b-modal>
</template>

<script>
// import { mapState } from "vuex";
export default {
    props: [
        'selectedJob'
    ],
    methods: {
        log: function(){
            this.$store.commit("updateJob")
            this.$bvModal.hide("editJobModal")
            this.$router.push('/')
        },
        cancel: function(){            
            this.$store.commit('setLogType', null)
            this.$store.commit('setLogTime', null)
            this.$store.commit('setLogLocation', null)
            this.$bvModal.hide("editJobModal")
            this.$router.push('/')
        },
    },
    computed: {
        isDisable: function() {
            return this.$store.logTime
        }
    }
}
</script>