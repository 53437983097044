<template>
    <div id="app">
        <div id="blanker" v-if="$store.getters.uploading" class="d-flex justify-content-center align-items-center">
            <span class="uploadIcon text-center">
                <b-icon icon="arrow-clockwise" animation="spin" class="text-light" font-scale="4"></b-icon>
                <h2 class="text-light">Contacting server...</h2>
            </span>
        </div>
        <vue-confirm-dialog></vue-confirm-dialog>
        <NetworkLogin v-if="$store.getters.logStatus == 0" />
        <SetPin v-if="$store.getters.logStatus == 1" />
        <LocalLogin v-else-if="$store.getters.logStatus == 2" />
        <div v-else-if="$store.getters.logStatus == 3">
            <Navbar />
            <router-view></router-view>      
            <Settings />
        </div>
    </div>
</template>

<script>
import NetworkLogin from './components/NetworkLogin.vue'
import SetPin from './components/SetPin.vue'
import LocalLogin from './components/LocalLogin.vue'
import Navbar from './components/Nav-Bar.vue'
import Settings from './components/App-Settings.vue'

export default {
    name: 'App',
    components: {
        NetworkLogin,
        SetPin,
        LocalLogin,
        Navbar,
        Settings
    }
}
</script> 

<style>
body{
    background-color: rgb(238, 238, 238)!important;
    padding-top: 60px;
}
.alert{
    position: fixed !important;
    top: 1rem;
    left: 1rem;
    right: 1rem;
}
#blanker{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    background-color: rgba(41, 41, 41, 0.863);
}
</style>