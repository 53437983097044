<template>
    <b-card 
        class="mb-2" 
        v-bind:class="[{ noCall: job.jobType == 'N' },{ training: job.jobType == 'T'},{ notToday: moment(job.jobStart).format('YYYYMMDD') != moment().format('YYYYMMDD')}]"
        border-variant="primary"
    >
        <b-card-text>
            <b-row @click="focus" class="d-flex align-items-stretch">
                <b-col sm="12" class="d-flex justify-content-between">
                    <p>
                        <span class="fw-bold">{{ job.jobClientName }}</span><br>
                        <span class="fw-bold">{{ moment(job.jobStart).format('H:mm') }} - {{ moment(job.jobEnd).format('H:mm') }}</span><br>
                        {{ job.jobClientAddress }}
                    </p>
                    <p class="fw-bold">{{ moment(job.jobStart).format('DD/MM/YYYY') }}</p>
                </b-col>
                <b-col sm="12" v-if="$store.getters.selectedJob == job.jobId">
                    <p>{{ job.jobDetail }}</p>
                    <b-row>
                        <b-col v-if="job.jobType != 'N'">
                            <b-button block @click="openMap">Map</b-button>
                        </b-col>
                        <b-col v-if="job.jobLogIn == null && job.jobType == 'J' && moment(job.jobStart).format('YYYYMMDD') == moment().format('YYYYMMDD')">
                            <b-button block variant="success" @click="logIn">Log In</b-button>
                        </b-col>
                        <b-col v-if="job.jobLogIn != null && job.jobType == 'J' && moment(job.jobStart).format('YYYYMMDD') == moment().format('YYYYMMDD')">
                            <b-button block variant="success" @click="logOut">Log Out</b-button>
                        </b-col>
                        <b-col>
                            <b-button block variant="danger" @click="clear" v-if="moment(job.jobStart).format('YYYYMMDD') == moment().format('YYYYMMDD')">Clear</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>            
        </b-card-text>
    </b-card> 
</template>

<script>
export default {
    props: [
        'job',
        'selectedJob'
    ],
    methods: {
        focus: function(){
            this.$emit("focused", this.job.jobId)
        },
        openMap: function(){
            window.open("https://maps.apple.com/?q=" + this.job.jobPostcode, '_system')
        },
        logIn: function(){
            this.$store.commit('setLogType', 'In')
            this.$router.push('/ScannerPage')
        },
        logOut: function(){
            this.$store.commit('setLogType', 'Out')
            this.$router.push('/ScannerPage')
        },
        clear: function(){
            this.$confirm({
                message: `WARNING: This will mark the job as cleared. Do you wish to continue?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        this.$store.commit('clearJob')
                    }
                }
            })
        },
    }
}
</script>

<style scoped>
.active{
    background-color: white !important;
    color: black !important;
}
.noCall{
    background-color: #919191;
}
.training{
    border-color: #d98d00!important;
    background-color: #eeff07!important;
}
.notToday{
    background-color: #919191;
    color: #4e4e4e;
}
.card{
    border-width: 1px 1px 1px 7px;
    font-size: 14px;
}
</style>