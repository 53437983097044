<template>
    <b-container>
        <b-row class="vh-100 d-flex justify-content-center align-items-center">
            <b-col sm="4">
                <h1>Choose PIN</h1>
                <div class="form-group mt-3">
                    <label for="pin1"><h4>PIN (4 Characters or more)</h4></label>
                    <input @input="changePin1" name="pin1" type="password" class="form-control" id="pin1">
                </div>
                <div class="form-group mt-3">
                    <label for="pin2"><h4>Verify PIN</h4></label>
                    <input @input="changePin2" name="pin2" type="password" class="form-control" id="pin2">
                </div>
                <div class="form-group mt-3">
                    <button v-bind:disabled="isDisable()" @click="setPin" class="btn btn-primary btn-block">Set PIN</button>
                </div>
                <div v-if="errorMessage != ''" class="alert alert-danger text-center">
                    {{ errorMessage }}
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    data: function() {
        return {
            pin1: '',
            pin2: '',
            errorMessage: ''
        }
    },
    methods: {
        changePin1: function(event) {
            this.pin1 = event.target.value
        },
        changePin2: function(event) {
            this.pin2 = event.target.value
        },
        isDisable: function() {
            if(this.pin1.length > 3 && this.pin2.length > 0){
                if(this.pin1 == this.pin2){
                    this.errorMessage = ""
                    return false
                }else{
                    this.errorMessage = "PINs don't match"
                    return true
                }
            }else{
                this.errorMessage = ""
                return true                
            }
        },
        setPin: function(){
            this.$store.commit('setUserPin', this.pin1)
            this.$store.commit('setLogStatus', 3)
        }
    }
}
</script>

<style>
.btn-block{
    width: 100%;
}
</style>