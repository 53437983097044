import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persist-indexeddb"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            userId: 0,
            username: String,
            pin: '',
            logStatus: 0
        },
        jobsToDo: [],
        jobsCompleted: [],
        selectedJob: -1,
        uploading: false,        
        added: 0,
        exists: 0,
        uploadCount: 0,
        apikey: 'SIc1wD-u[)02jP(m21D=V$268x9zvT_Fz<4DB@CE#0rr09O]',
        logType: null,
        logLocation: null,
        logTime: null
    },
mutations: {
    toggleUploading(state) {
        if(state.uploading === true){
            state.uploading = false
        }else{
            state.uploading = true
        }
    },
    setSelectedJobData(state, values) {
        state.selectedJobData = Object.assign({}, state.jobData.selectedJobData, values)
    },
    setRevertData(state, values) {
        state.revertData = Object.assign({}, state.jobData.revertData, values)
    },
    setUsername(state, username) {
        state.user.username = username
    },
    setUserId(state, userId) {
        state.user.userId = userId
    },
    setUserPin(state, pin) {
        state.user.pin = pin
    },
    setLogStatus(state, logStatus) {
        state.user.logStatus = logStatus
    },
    setSelectedJob(state, selectedJob) {
        state.selectedJob = selectedJob
    },
    setLogType(state, logType) {
        state.logType = logType
    },
    setLogLocation(state, logLocation) {
        state.logLocation = logLocation
    },
    setLogTime(state, logTime) {
        state.logTime = logTime
    },
    addJobs(state, theJobs) {
        state.added = 0
        state.exists = 0
        var keys = Object.keys(theJobs);
        for (let i of keys) {
            var index = state.jobsToDo.findIndex(x => x.jobId == theJobs[i].jobId)

            if(index === -1){
                theJobs[i].jobLogIn = null
                theJobs[i].jobLogOut = null
                theJobs[i].jobLogInLocation = null
                theJobs[i].jobLogOutLocation = null
                theJobs[i].isComplete = 0

                state.jobsToDo.push(theJobs[i])
                state.added += 1
            }else{
                var data = state.jobsToDo[index]
                data.jobClientAddress = theJobs[i].jobClientAddress
                data.jobClientName = theJobs[i].jobClientName
                data.jobDetail = theJobs[i].jobDetail
                data.jobEnd = theJobs[i].jobEnd
                data.jobPostcode = theJobs[i].jobPostcode
                data.jobReference = theJobs[i].jobReference
                data.jobStart = theJobs[i].jobStart
                data.jobType = theJobs[i].jobType
                state.exists += 1
            }
        }
        var alertString = ''
        if(state.added === 0 && state.exists === 0){
            alertString = "No jobs found to download"
        }else{
            if(state.added > 0){
                alertString += state.added + ' new jobs added.\n\r'
            }
            if(state.exists > 0){
                alertString += state.exists + ' jobs updated.'
            }
        }
        alert(alertString)
    },
    updateJob(state){
        var index = state.jobsToDo.findIndex(x => x.jobId == state.selectedJob)
        var data = state.jobsToDo[index]
        if(state.logType == 'In'){
            data.jobLogInLocation = state.logLocation
            data.jobLogIn = state.logTime
            state.jobsToDo = [...state.jobsToDo.map(item => item.jobId !== state.selectedJob ? item : {...item, ...data})]
        }
        if(state.logType == 'Out'){
            data.jobLogOutLocation = state.logLocation
            data.jobLogOut = state.logTime
            data.isComplete = 1
            state.jobsToDo = [...state.jobsToDo.map(item => item.jobId !== state.selectedJob ? item : {...item, ...data})]
        }

    },
    clearJob(state){
        var index = state.jobsToDo.findIndex(x => x.jobId == state.selectedJob)
        var data = state.jobsToDo[index]
        data.jobLogIn = 'Cleared'
        data.jobLogOut = 'Cleared'
        data.jobLogInLocation = new Date().toISOString()
        data.jobLogOutLocation = new Date().toISOString()
        data.isComplete = 1
        state.jobsToDo = [...state.jobsToDo.map(item => item.jobId !== state.selectedJob ? item : {...item, ...data})]
    },
    setJobsToDo(state, data){
        state.jobsToDo = data
    },
    setJobByOrder(state, data){
        state.jobsToDo.filter(x => x.jobOrder == state.selectedJob)[0] = data
        console.log("saving data now")
    },
    saveJobData(state, data) {
        state.jobsToDo = [...state.jobsToDo.map(item => item.jobId !== data.jobId ? item : {...item, ...data})]
    },
    finishJob(state, data){   
        data.isComplete = 1  
        var d = new Date().toISOString().slice(0, 19).replace('T', ' ')
        data.dateFinished = d       
        state.jobsToDo = [...state.jobsToDo.map(item => item.jobId !== data.jobId ? item : {...item, ...data})]
    },
    makeJobTodo(state){
        state.jobData.selectedJobData.isComplete = 0
        this.saveJobData()
    },
    setUploadCount(state, value){
        state.uploadCount = value
    },
    deleteJob(state, id){
        console.log(id)
        state.jobsToDo = state.jobsToDo.filter(x => x.jobId != id)
    }
},
getters: {
    userId: state => state.user.userId,
    uploading: state => state.uploading,
    username: state => state.user.username,
    pin: state => state.user.pin,
    logStatus: state => state.user.logStatus,
    user: state => state.user,
    uploadCount: state => state.uploadCount,
    apikey: state => state.apikey,
    jobsToDo: state => state.jobsToDo.filter(job => job.isComplete === 0),
    jobsCompleted: state => state.jobsToDo.filter(job => job.isComplete === 1),
    selectedJob: state => state.selectedJob,
    logType: state => state.logType,
    logLocation: state => state.logLocation,
    logTime: state => state.logTime,
    selectedJobData: state => {
        if(state.jobsToDo != [] && state.selectedJob > 0){
            return state.jobsToDo.filter(job => job.jobId == state.selectedJob)[0]
        }else{
            return []
        }
    }
},
plugins: [createPersistedState()],
})
